import { useContext, useEffect, useState } from "react";
import { SetupContext } from "../index.js";
import { useProvider } from "./useProvider.js";
import { ethers } from "ethers";

const idToNameMapping = {
  0: "Humble Plaque",
  1: "Stone Tombstone",
  2: "Obsidian Obelisk",
  3: "Marble Crypt",
};

export const useTombstones = () => {
  const { address, tombstonesContract, tombstonesAbi } =
    useContext(SetupContext);
  const { provider, signer } = useProvider();

  const [tombstoneObjects, setTombstoneObjects] = useState([]);

  const tombstonesContractConnected = !!signer
    ? new ethers.Contract(tombstonesContract, tombstonesAbi, provider).connect(
        signer
      )
    : undefined;

  const getTombstoneItems = async () => {
    const tombstoneItems = await tombstonesContractConnected.getItems();
    createTombstoneItems(tombstoneItems);
  };

  async function createTombstoneItems(tombstoneItems) {
    const tombstonesCollection = await Promise.all(
      tombstoneItems.map(async (tombstoneItem) => {
        const userQuantity = await tombstonesContractConnected.balanceOfBatch(
          [address],
          [tombstoneItem.id.toNumber()]
        );
        let uri = tombstoneItem.uri;
        let data = {};

        if (uri.includes("ipfs://")) {
          const response = await fetch(
            `https://gateway.pinata.cloud/ipfs/${uri.split("ipfs://")[1]}`
          );
          data = await response.json();
        } else if (uri.includes("https://gateway.pinata.cloud/ipfs/")) {
          const response = await fetch(uri);
          data = await response.json();
        }
        return {
          id: tombstoneItem.id.toNumber(),
          name: idToNameMapping[tombstoneItem.id.toNumber()],
          image: data.image,
          userBalance: userQuantity.toString(),
        };
      })
    );
    setTombstoneObjects(tombstonesCollection);
  }

  useEffect(() => {
    getTombstoneItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, tombstonesContract, tombstonesAbi]);

  return {
    tombstoneObjects,
  };
};
