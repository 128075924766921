import Gutter from "./Gutter";
import "./Alchemy.scss";

import { usePrimordia } from "../hooks/usePrimordia";
import allBlood from "../assets/all-blood.png";
import d1Button from "../assets/d1.png";
import d2Button from "../assets/d2.png";
import d3Button from "../assets/d3.png";
import legendaryButton from "../assets/legendary.png";
import burnAll from "../assets/burnall.png";
import addButton from "../assets/add-button-alchemy.png";
import addButtonHovered from "../assets/add-button-alchemy-hover.png";
import loadingAnimation from "../assets/rolling-loading-animation.gif";
import reRollDragonButton from "../assets/reroll-dragon-button.png";
import reRollDragonButtonHovered from "../assets/reroll-dragon-button-hover.png";
import { useEffect, useState } from "react";
import { useWeaponBurn } from "../hooks/useWeaponBurn";
import removeButton from "../assets/minus-button.png";
import removeButtonHover from "../assets/minus-button-hover.png";
import clearAllButton from "../assets/clear-all-button.png";
import clearAllButtonHover from "../assets/clear-all-button-hover.png";
import blackBox from "../assets/black-box.png";

import burnAllHover from "../assets/burnall-hover.png";
import d1ButtonHover from "../assets/d1-hover.png";
import d2ButtonHover from "../assets/d2-hover.png";
import d3ButtonHover from "../assets/d3-hover.png";
import legendaryButtonHover from "../assets/legendary-hover.png";
import { useDragons } from "../hooks/useDragons";

const Alchemy = () => {
  const {
    primordiaWeapons,
    primordiaBlood,
    getPrimordiaItems,
    createPrimordiaObject,
  } = usePrimordia();
  const { dragonsContractConnected, ownerDragons, getTokenIdsByOwner } =
    useDragons();
  const { weaponBurnContractConnected } = useWeaponBurn();
  const [userWeapons, setUserWeapons] = useState(primordiaWeapons);

  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState("");
  const [showDragonModal, setShowDragonModal] = useState(false);
  const [burnTx, setBurnTx] = useState(null);
  const [dragonData, setDragonData] = useState(null);
  const [userDragons, setUserDragons] = useState();
  const [showRerollModal, setShowRerollModal] = useState(false);
  const [rerollTx, setRerollTx] = useState(null);
  const [rerollData, setRerollData] = useState(null);
  const [numberOfPanes, setNumberOfPanes] = useState(1);
  const [removeButtonHovered, setRemoveButtonHovered] = useState(false);
  const [isReRollButtonHovered, setIsReRollButtonHovered] = useState(false);
  const [isClearButtonHovered, setIsClearButtonHovered] = useState(false);
  const [isAddButtonHovered, setIsAddButtonHovered] = useState(false);
  const [selectedWeapons, setSelectedWeapons] = useState([]);
  const [selectedDragons, setSelectedDragons] = useState([]);

  let endpoint = "https://moonrunners-dragons.herokuapp.com/dragons/tx";
  let imageEndpoint =
    "https://moonrunners-dragons.herokuapp.com/dragons/image/";

  if (window.chain === "goerli") {
    endpoint = "https://blood-burn-test.herokuapp.com/dragons/tx";
    imageEndpoint = "https://blood-burn-test.herokuapp.com/dragons/image/";
  }

  const getButtonImage = (bloodId) => {
    switch (bloodId) {
      case 4:
        return hovered === 4 ? legendaryButtonHover : legendaryButton;
      case 5:
        return hovered === 5 ? d3ButtonHover : d3Button;
      case 6:
        return hovered === 6 ? d2ButtonHover : d2Button;
      case 7:
        return hovered === 7 ? d1ButtonHover : d1Button;
      case 1:
        return hovered === 1 ? burnAllHover : burnAll;
      default:
        return d1Button;
    }
  };

  useEffect(() => {
    if (primordiaBlood.length > 0) {
      setLoading(false);
      setUserDragons(ownerDragons);
      setUserWeapons(primordiaWeapons);
    }
  }, [primordiaBlood, ownerDragons, userDragons]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortedBlood = [...primordiaBlood].reverse();

  const userBalances = sortedBlood.map((blood) => blood.userBalance);

  const openDragonModal = () => {
    setShowDragonModal(true);
  };

  const closeDragonModal = () => {
    setShowDragonModal(false);
    setBurnTx(null);
  };

  const openRerollModal = () => {
    setShowRerollModal(true);
  };

  const closeRerollModal = async () => {
    setShowRerollModal(false);
    setRerollTx(null);
  };

  const checkTransactionStatus = async (txHash, onSuccess) => {
    console.log("check tx status called");
    openDragonModal();
    setDragonData(null);
    setBurnTx(txHash);

    const response = await fetch(`${endpoint}/${txHash}`);
    const data = await response.json();

    if (data.length > 0) {
      console.log("Transaction was successful");
      onSuccess(data);
    } else {
      console.log("Transaction is still pending...");
      setTimeout(() => {
        checkTransactionStatus(txHash, onSuccess);
      }, 5000); // Poll again after 5 seconds
    }
  };

  const checkTransactionStatusReroll = async (txHash, onSuccess) => {
    console.log("check tx status for re-roll called");
    console.log(txHash);
    openRerollModal();
    setRerollData(null);
    setRerollTx(txHash);

    const response = await fetch(`${endpoint}/${txHash}`);
    const data = await response.json();

    if (data.length > 0) {
      console.log("Transaction was successful");
      onSuccess(data);
    } else {
      console.log("Transaction is still pending...");
      setTimeout(() => {
        checkTransactionStatusReroll(txHash, onSuccess);
      }, 5000); // Poll again after 5 seconds
    }
  };

  const onSuccess = async (data) => {
    console.log("Success:", data);
    setDragonData(data);
    setRerollData(data);
    getTokenIdsByOwner();
    const updatedDragons = await getTokenIdsByOwner();
    setUserDragons(updatedDragons);
    setSelectedWeapons([]);
    setSelectedDragons([]);
    setNumberOfPanes(1);
    const refetchedWeapons = await getPrimordiaItems();
    const updatedWeapons = await createPrimordiaObject(refetchedWeapons);
    setUserWeapons(updatedWeapons);
  };

  async function burnBlood(bloodId, userBloodBalance) {
    if (bloodId.length > 1) {
      const numberOfCommonMints = Math.floor(userBloodBalance[0] / 5);
      const numberOfRareMints = Math.floor(userBloodBalance[1] / 4);
      const numberOfEpicMints = Number(userBloodBalance[2]);
      const numberOfLegendaryMints = Number(userBloodBalance[3]);

      const repeatedBloodIds = [];
      for (let i = 0; i < bloodId.length; i++) {
        const bloodQuantity = [
          numberOfCommonMints,
          numberOfRareMints,
          numberOfEpicMints,
          numberOfLegendaryMints,
        ][i];
        repeatedBloodIds.push(...Array(bloodQuantity).fill(bloodId[i]));
      }

      console.log(repeatedBloodIds);

      const tx = await dragonsContractConnected.burnBloodForDragons(
        repeatedBloodIds
      );
      console.log(tx.hash);
      checkTransactionStatus(tx.hash, onSuccess);
    } else {
      const tx = await dragonsContractConnected.burnBloodForDragons([bloodId]);
      checkTransactionStatus(tx.hash, onSuccess);
    }
  }

  const incrementPane = () => {
    if (selectedWeapons.length < 5) {
      setSelectedWeapons([...selectedWeapons, null]);
      setNumberOfPanes(numberOfPanes + 1);
    }
  };

  const handleWeaponClick = (index, weapon) => {
    const newSelectedWeapons = [...selectedWeapons];
    newSelectedWeapons[index] = weapon;

    setSelectedWeapons(newSelectedWeapons);
  };

  const handleDragonClick = (dragon) => {
    console.log(numberOfPanes);
    const newSelectedDragons = [...selectedDragons];
    if (newSelectedDragons.length === numberOfPanes) {
      console.log("Max number of dragons already selected");
      return;
    }
    if (!newSelectedDragons.includes(dragon)) {
      newSelectedDragons.push(dragon);
      setSelectedDragons(newSelectedDragons);
      console.log(newSelectedDragons);
    } else {
      console.log("Dragon already selected:", dragon);
    }
  };

  async function reRollDragon() {
    if (selectedDragons.length < 1 || selectedWeapons.length < 1) {
      return;
    }
    console.log(selectedDragons.length);
    console.log(selectedWeapons);
    const selectedWeaponIds = selectedWeapons.map((weapon) => weapon.id);
    const selectedDragonIds = selectedDragons.map((dragon) => dragon.tokenId);

    const tx2 = await weaponBurnContractConnected.rerollDragons(
      selectedWeaponIds,
      selectedDragonIds
    );

    console.log(tx2.hash);
    checkTransactionStatusReroll(tx2.hash, onSuccess);
  }

  const clearSelection = () => {
    setSelectedDragons([]);
  };

  const decrementPane = (index) => {
    console.log(index);
    if (numberOfPanes > 1) {
      const newSelectedWeapons = selectedWeapons.filter((_, i) => i !== index);
      const newSelectedDragons = selectedDragons.filter((_, i) => i !== index);
      setSelectedWeapons(newSelectedWeapons);
      setSelectedDragons(newSelectedDragons);
      setNumberOfPanes(numberOfPanes - 1);

      console.log(newSelectedDragons);
      console.log(newSelectedWeapons);
    }
  };

  return (
    <>
      <div className="container">
        <section className="section">
          <h1 className="alchemy-title">The Alchemy Lab</h1>
          <div className="columns den-info">
            <div className="column">
              <p className="alchemy-text">
                Brave Moonrunners, it's time to ignite the dragon blood and
                forge your own dragon army. but this will come at a cost, this
                page has a special purpose.
                <br />
                All blood and dragon related utilities will be showcased here.
              </p>
            </div>
          </div>
        </section>

        <section className="section">
          <h1 className="alchemy-title">Burn blood to dragon</h1>
          <div className="columns alchemy-text">
            <div className="column">
              <h2>RATIO'S EXPLAINED</h2>
              <br />
              <br />
              5x Almanazar of Dragon Blood = 1x D1 (Common) Dragon
              <br />
              4x Balthazar of Dragon Blood = 1x D2 (Rare) Dragon
              <br />
              1x Nebuchadnezzar of Dragon Blood = 1x D3 (Epic) Dragon
              <br />
              1x Melchizedek of Dragon Blood <span className="equals">
                =
              </span>{" "}
              1x Legendary
            </div>
          </div>
          {loading ? (
            <p> Connect wallet to see your blood</p>
          ) : (
            <div className="columns den-info is-vcentered">
              <div className="column is-8">
                <div className="columns weapon-image-container">
                  {sortedBlood.map((blood) => (
                    <div className="column training-weapon is-centered">
                      <div className="is-flex is-flex-direction-column has-text-centered">
                        <img
                          className="weapon-image"
                          src={blood.image}
                          alt="blood"
                        />
                        <img
                          className="blood-button"
                          src={getButtonImage(blood.id)}
                          alt="blood"
                          onMouseEnter={() => setHovered(blood.id)}
                          onMouseLeave={() => setHovered("")}
                          onClick={() => burnBlood(blood.id, blood.userBalance)}
                        />
                      </div>
                      <div className="weapon-counter">
                        <div className="item-count"> {blood.userBalance} </div>
                      </div>
                    </div>
                  ))}
                  <div className="column training-weapon is-centered">
                    <div className="is-flex is-flex-direction-column has-text-centered">
                      <img
                        className="weapon-image"
                        src={allBlood}
                        alt="blood"
                      />
                      <img
                        className="blood-button"
                        src={getButtonImage(1)}
                        alt="blood"
                        onMouseEnter={() => setHovered(1)}
                        onMouseLeave={() => setHovered("")}
                        onClick={() => burnBlood([7, 6, 5, 4], userBalances)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>

        <section className="section">
          <h1 className="alchemy-title">REROLL YOUR DRAGON(S)</h1>
          <div className="columns den-info">
            <div className="column">
              <p className="alchemy-text">
                TIME HAS COME, HATE YOUR DRAGON? THROW IT IN THE TRASHCAN!
                <br />
                TRUE DUAL UTILITY FOR WEAPONS. YOU CAN NOW REROLL YOUR DRAGON.{" "}
                <br />
                TREAD CAREFULLY, YOU CAN ROLL HIGHER, BUT ALSO LOWER!
                <br />
              </p>
            </div>
          </div>

          {Array.from({ length: numberOfPanes }).map((_, index) => (
            <>
              <div
                className={`mt-4 columns is-centered is-vcentered pane-${
                  index + 1
                }`}
              >
                <div className="column is-4 has-text-centered selected-weapon">
                  {selectedWeapons[index] ? (
                    <img
                      className="selected-image"
                      src={selectedWeapons[index].image || ""}
                      alt="Selected Weapon"
                    />
                  ) : (
                    <img
                      className="selected-image"
                      src={blackBox}
                      alt="blackBox"
                    ></img>
                  )}
                </div>
                <div className="column is-3 has-text-centered">
                  {index === 0 && (
                    <>
                      <img
                        id="rerollButton"
                        src={
                          isReRollButtonHovered
                            ? reRollDragonButtonHovered
                            : reRollDragonButton
                        }
                        alt="reroll dragon"
                        onClick={() => reRollDragon()}
                        onMouseEnter={() => setIsReRollButtonHovered(true)}
                        onMouseLeave={() => setIsReRollButtonHovered(false)}
                        className="mb-3"
                      />
                      <br />
                      <img
                        src={
                          isClearButtonHovered
                            ? clearAllButtonHover
                            : clearAllButton
                        }
                        alt="clear all "
                        className="mb-3"
                        id="clearDragons"
                        onClick={() => clearSelection()}
                        onMouseEnter={() => setIsClearButtonHovered(true)}
                        onMouseLeave={() => setIsClearButtonHovered(false)}
                      ></img>
                      <br />
                    </>
                  )}
                  <img
                    src={isAddButtonHovered ? addButtonHovered : addButton}
                    id="addButton"
                    onMouseEnter={() => setIsAddButtonHovered(true)}
                    onMouseLeave={() => setIsAddButtonHovered(false)}
                    onClick={() => incrementPane()}
                    alt="add weapons"
                  />
                  <>
                    <span>
                      <img
                        className="remove-pane-button"
                        src={
                          removeButtonHovered ? removeButtonHover : removeButton
                        }
                        alt="remove weapons"
                        onClick={() => decrementPane(index)}
                        onMouseEnter={() => setRemoveButtonHovered(true)}
                        onMouseLeave={() => setRemoveButtonHovered(false)}
                      />
                    </span>
                    <br />
                  </>
                  <br />
                </div>
                <div className="column is-4 has-text-centered selected-dragon">
                  {selectedDragons[index] ? (
                    <>
                      <img
                        className="selected-image selected-dragon"
                        src={`${imageEndpoint}${
                          selectedDragons[index].tokenId
                        }?${Date.now()}`}
                        alt="Selected Dragon"
                      />
                      <br />
                      {/* <span className="dragon-label">#{selectedDragons[index].tokenId}</span> */}
                      <span className="dragon-label">
                        #{selectedDragons[index].tokenId} -{" "}
                        {selectedDragons[index].rarity}
                      </span>
                    </>
                  ) : (
                    <img
                      src={blackBox}
                      className="selected-image"
                      alt="blackBox"
                    ></img>
                  )}
                </div>
              </div>
              <div className="weapon-list-container">
                <div className="columns wepaon-list-centered is-centered my-4">
                  {userWeapons?.map((weapon) => (
                    <div className="column training-weapon">
                      <div key={weapon.id}>
                        <img
                          className={`weapon-image-small ${
                            weapon.userBalance < 1 ? "no-balance" : ""
                          }`}
                          onClick={() => handleWeaponClick(index, weapon)}
                          src={weapon.image}
                          alt={weapon.name}
                        />
                      </div>
                      <div className="weapon-counter">
                        <div className="item-count item-count-weapon-list">
                          {" "}
                          {weapon.userBalance}{" "}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ))}
        </section>

        <section className="section">
          <h1 className="alchemy-title">MY DRAGONHORDE</h1>
          {userDragons?.length > 0 ? (
            <div className="columns den-info is-multiline">
              {userDragons?.map((dragon) => (
                <div
                  key={dragon?.tokenId}
                  className={`image-column column is-2 is-vcentered token-container ${
                    dragon?.rarity === "Legendary" ? "legendary-dragon" : ""
                  }`}
                >
                  <img
                    onClick={() => handleDragonClick(dragon)}
                    alt="dragons"
                    src={`${imageEndpoint}${dragon?.tokenId}?${Date.now()}`}
                    className={"token-img"}
                    key={dragon?.tokenId}
                  />
                  {/* <div className="dragonhorde-label">#{dragon?.tokenId}</div> */}

                  <div className="dragonhorde-label">
                    #{dragon?.tokenId} - {dragon?.rarity}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>You have no dragonhorde</p>
          )}
        </section>

        {/* burning blood modal */}
        <div
          className={`modal ${showDragonModal ? "is-active" : ""}`}
          onBlur={closeDragonModal}
        >
          <div className="modal-background"> </div>
          <div className="modal-card">
            <header className="modal-card-head">
              {dragonData === null ? (
                <p className="modal-card-title">Burning blood for dragon</p>
              ) : (
                <p className="modal-card-title">Your Dragons</p>
              )}
              <button
                className="delete is-large"
                aria-label="close"
                onClick={closeDragonModal}
              />
            </header>

            <section className="modal-card-body">
              {dragonData === null ? (
                <p>
                  Burning blood... You can watch your tx here:
                  <a
                    target="_blank"
                    href={`https://${
                      window.chain === "goerli" ? "goerli." : ""
                    }etherscan.io/tx/${burnTx}`}
                    rel="noreferrer"
                  >
                    Tx hash
                  </a>
                </p>
              ) : (
                <div>
                  <div className="columns is-centered is-multiline">
                    {dragonData.map((dragon, index) => (
                      <div className="column is-4 has-text-centered">
                        <img
                          src={`data:image/png;base64,${dragon.image}`}
                          alt={`Dragon ${index}`}
                        />
                        <br />
                        <div>{dragon.rarity}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/* reroll dragon modal */}
        <div
          className={`modal ${showRerollModal ? "is-active" : ""}`}
          onBlur={closeRerollModal}
        >
          <div className="modal-background"> </div>
          <div className="modal-card">
            <header className="modal-card-head">
              {dragonData === null ? (
                <p className="modal-card-title">Rerolling your dragon(s)</p>
              ) : (
                <p className="modal-card-title">Your new dragonhorde</p>
              )}
              <button
                className="delete is-large"
                aria-label="close"
                onClick={closeRerollModal}
              />
            </header>

            <section className="modal-card-body">
              {rerollData === null ? (
                <p>
                  Your dragon(s) will show up in here after the&nbsp;
                  <a
                    target="_blank"
                    href={`https://${
                      window.chain === "goerli" ? "goerli." : ""
                    }etherscan.io/tx/${rerollTx}`}
                    rel="noreferrer"
                  >
                    transaction&nbsp;
                  </a>
                  is confirmed...
                  <img
                    className="loading-dice"
                    src={loadingAnimation}
                    alt="loading"
                  ></img>
                </p>
              ) : (
                <div>
                  <div className="dragon-images-modal-container">
                    {dragonData.map((dragon, index) => (
                      <div className="dragon-image-modal">
                        <img
                          src={`data:image/png;base64,${dragon.image}`}
                          alt={`Dragon ${index}`}
                        />
                        <br />
                        {/* <div>#{dragon?.tokenId}</div> */}
                        <div className="rarity-label-modal">
                          #{dragon?.tokenId} - {dragon.rarity}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        <Gutter />
      </div>
    </>
  );
};

export default Alchemy;
