import "./Trophies.css";

import clock from "../assets/Clock.gif";
import trophy1 from "../assets/trophy1.png";
import trophy2 from "../assets/trophy2.png";
import trophy3 from "../assets/trophy3.png";
import trophy4 from "../assets/trophy4.png";

import { useTrophies } from "../hooks/useTrophies";
import { useTokens } from "../hooks/useTokens";
import { useDragons } from "../hooks/useDragons";
import { useEffect, useState } from "react";

const Trophies = () => {
  const { claimTime } = useTrophies();
  const { stakedMoonrunners } = useTokens();
  const { stakedDragons } = useDragons();
  const [totalStaked, setTotalStaked] = useState();

  useEffect(() => {
    setTotalStaked(stakedMoonrunners.length + stakedDragons.length);
  }, [stakedDragons, stakedMoonrunners]);

  return (
    <>
      <div className="columns is-centered is-vcentered trophy-time-text">
        <div className="column">
          <div>
            Pledged&nbsp;
            <strong className="trophies-strong">{totalStaked}</strong>
            &nbsp;assets
          </div>
          <h1 className="trophy-text">
            &nbsp;
            <span>
              {totalStaked >= 25
                ? " Diamond Trophy"
                : totalStaked >= 10
                ? " Gold Trophy"
                : totalStaked >= 5
                ? " Silver Trophy"
                : totalStaked >= 1
                ? " Bronze Trophy"
                : "No trophy available "}
            </span>
            {totalStaked >= 1 && (
              <>
                &nbsp;
                <img
                  className="trophy-claim-image"
                  src={
                    totalStaked >= 25
                      ? trophy4
                      : totalStaked >= 10
                      ? trophy3
                      : totalStaked >= 5
                      ? trophy2
                      : totalStaked >= 1
                      ? trophy1
                      : null
                  }
                  alt="trophy"
                />
              </>
            )}
            {totalStaked > 0 && claimTime && <span>{claimTime}</span>}
          </h1>
        </div>
        <div className="column is-2">
          {totalStaked > 0 && claimTime && (
            <img className="clock-img" src={clock} alt="clock" />
          )}
        </div>
      </div>
      <div className="column is-1"></div>
    </>
  );
};

export default Trophies;
