import { useState } from "react";
import den from "../assets/den-animated.gif";
import stakingImage from "../assets/moonrunners-button.png";
import primordia from "../assets/primordia-button.png";
import primordiaYellow from "../assets/primordia-button-yellow.png";
import alchemy from "../assets/alchemy-button.png";
import alchemyButtonYellow from "../assets/alchemy-button-yellow.png";
import landImage from "../assets/land-button.png";
import landImageYellow from "../assets/land-button-yellow.png";
import raffle from "../assets/raffle-button.png";
import raffleButtonYellow from "../assets/raffle-button-yellow.png";
import faqImage from "../assets/faq-button.png";
import moon from "../assets/moon.png";
import stakingImageYellow from "../assets/moonrunners-button-yellow.png";
import faqImageYellow from "../assets/faq-button-yellow.png";
import twitterIcon from "../assets/twitter-mr.png";
import discordIcon from "../assets/discord-mr.png";
import openseaIcon from "../assets/opensea-mr.png";
import game from "../assets/game-button.png";
import gameYellow from "../assets/game-yellow.png";

import { Outlet, Link } from "react-router-dom";
import "./Navbar.css";
import { useProvider } from "../hooks/useProvider";
import { useEffect } from "react";

const staking = "staking";
const faq = "faq";
const land = "land";

const Navbar = () => {
  const [hovered, setHovered] = useState("");
  const { provider, signer } = useProvider();
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState("not connected");

  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          const fullAddress = accounts[0];
          const shortenedAddress = `${fullAddress.slice(
            0,
            4
          )}...${fullAddress.slice(-4)}`;
          setAddress(shortenedAddress);
        } else {
          setAddress("not connected");
        }
      };

      window.ethereum.on("accountsChanged", handleAccountsChanged);

      window.ethereum
        .request({ method: "eth_accounts" })
        .then(handleAccountsChanged)
        .catch((error) => {
          console.error(error);
          setAddress("not connected");
        });

      return () => {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      };
    }
  }, []);

  return (
    <>
      <div className="social-links">
        <a href="https://www.twitter.com/MoonrunnersNFT">
          <img src={twitterIcon} className="social-icon" alt="moon" />
        </a>
        <a href="https://discord.gg/moonrunners">
          <img src={discordIcon} className="social-icon" alt="moon" />
        </a>
        <a href="https://opensea.io/collection/moonrunnersnft">
          <img src={openseaIcon} className="social-icon" alt="moon" />
        </a>
        <br />
        <div className="wallet-address">{address}</div>
      </div>

      <div className="container">
        <img src={den} className="den-logo" alt="Den" />
        <Link to="/roadmap">
          <img src={moon} className="moon-img" alt="moon" />
        </Link>
        <div id="moonrunnersNavbar" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/staking">
              <img
                src={hovered === staking ? stakingImageYellow : stakingImage}
                className="nav-img"
                id="moonrunnersImage"
                onMouseEnter={() => setHovered("staking")}
                onMouseLeave={() => setHovered("")}
                alt="staking"
              />
            </Link>
            <Link to="/primordia">
              <img
                src={hovered === "primordia" ? primordiaYellow : primordia}
                className="nav-img "
                alt="primordia"
                onMouseEnter={() => setHovered("primordia")}
                onMouseLeave={() => setHovered("")}
              />
            </Link>
            <Link to="/raffles">
              <img
                src={hovered === "raffles" ? raffleButtonYellow : raffle}
                className="nav-img "
                alt="raffles"
                onMouseEnter={() => setHovered("raffles")}
                onMouseLeave={() => setHovered("")}
              />
            </Link>
            <Link to="/alchemy">
              <img
                src={hovered === "alchemy" ? alchemyButtonYellow : alchemy}
                className="nav-img"
                alt="Alchemy"
                onMouseEnter={() => setHovered("alchemy")}
                onMouseLeave={() => setHovered("")}
              />
            </Link>
            <a href="https://stake.primordia.io/">
              <img
                src={hovered === land ? landImageYellow : landImage}
                className="nav-img"
                alt="Land"
                onMouseEnter={() => setHovered(land)}
                onMouseLeave={() => setHovered("")}
              />
            </a>
            <Link to="/faq">
              <img
                src={hovered === faq ? faqImageYellow : faqImage}
                className="nav-img"
                alt="FAQ"
                onMouseEnter={() => setHovered(faq)}
                onMouseLeave={() => setHovered("")}
              />
            </Link>
            <a
              href="https://flappydragon.moonrunners.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={hovered === game ? gameYellow : game}
                className="nav-img"
                alt="Twitter"
                onMouseEnter={() => setHovered(game)}
                onMouseLeave={() => setHovered("")}
              />
            </a>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Navbar;
