import { useContext, useEffect, useState } from "react";
import { SetupContext } from "../index.js";
import { useProvider } from "./useProvider.js";
import { ethers } from "ethers";

export const useDragons = () => {
  const {
    address,
    dragonsContract,
    dragonsAbi,
    trophiesContract,
    trophiesAbi,
  } = useContext(SetupContext);
  const { provider, signer } = useProvider();

  const [allDragons, setAllDragons] = useState([]);
  const [stakedDragons, setStakedDragons] = useState([]);
  const [unstakedDragons, setUnstakedDragons] = useState([]);

  const [ownerDragons, setOwnerDragons] = useState([null]);

  const dragonsContractConnected = !!signer
    ? new ethers.Contract(dragonsContract, dragonsAbi, provider).connect(signer)
    : undefined;

  const trophiesContractConnected = !!signer
    ? new ethers.Contract(trophiesContract, trophiesAbi, provider).connect(
        signer
      )
    : undefined;

  let ownerDragonsEndpoint = `https://moonrunners-dragons.herokuapp.com/dragons/address`;
  let dragonDataEndpoint = "https://moonrunners-dragons.herokuapp.com/dragons";

  if (window.chain === "goerli") {
    ownerDragonsEndpoint = `https://blood-burn-test.herokuapp.com/dragons/address`;
    dragonDataEndpoint = "https://blood-burn-test.herokuapp.com/dragons";
  }

  async function getTokenIdsByOwner() {
    try {
      const response = await fetch(`${ownerDragonsEndpoint}/${address}`);
      const data = await response.json();

      const stake = await trophiesContractConnected.getStake(address);
      const stakedTokenIds = stake.tokenIds.map((tokenId) =>
        tokenId.toNumber()
      );

      const tokens = data.map((nft) => ({
        tokenId: nft.tokenId,
        rarity:
          nft.rarity ||
          nft.rawMetadata.attributes.find(
            (attr) => attr.trait_type === "Rarity"
          )?.value,
        isStaked: stakedTokenIds.includes(nft.tokenId - 20_000), // Check if the token ID is in stakedTokenIds
      }));
      const stakedDragonsPromises = stakedTokenIds
        .filter((id) => id >= 20_000) // Dragon ids have 20_000 added to them by the staking contract
        .map((id) => getStakedDragon(id - 20_000, true));

      const stakedDragons = await Promise.all(stakedDragonsPromises);
      setStakedDragons(stakedDragons);
      setOwnerDragons(tokens);
    } catch (error) {
      console.error(error);
    }
  }

  const getStakedDragon = async (id, isStaked) => {
    const response = await fetch(`${dragonDataEndpoint}/${id}`);
    const data = await response.json();
    const rarity = data.attributes.find(
      (attr) => attr.trait_type === "Rarity"
    ).value;
    const stakedDragon = {
      tokenId: id,
      rarity: rarity,
      isStaked,
    };

    return stakedDragon;
  };

  useEffect(() => {
    if (address) {
      getTokenIdsByOwner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, dragonsContract, dragonsAbi]);

  return {
    dragonsContract,
    dragonsContractConnected,
    ownerDragons,
    getTokenIdsByOwner,
    stakedDragons,
    allDragons,
  };
};
