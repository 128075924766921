import React from "react";

const Gutter = () => {
  return (
    <>
      <div
        className="gutter"
        style={{
          paddingTop: "15vh",
        }}
      ></div>
    </>
  );
};

export default Gutter;
