import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { SetupContext } from "../index.js";
import { useProvider } from "../hooks/useProvider.js";
import "./LoadingBar.css";
import bouncingWolf from "../assets/bouncing-wolf.gif";
import { useDragons } from "../hooks/useDragons.js";
import dragonHead from "../assets/dragon-head.png";

interface LoadingBarProps {
  showDragons: boolean;
  dragonsContractConnected: ethers.Contract;
  mrContractConnected: ethers.Contract;
}

interface SetupContextProps {
  mrContract: ethers.Contract;
  mrAbi: ethers.ContractInterface;
  trophiesContract: ethers.Contract;
}

const LoadingBar = ({
  showDragons,
  dragonsContractConnected,
  mrContractConnected,
}) => {
  const [percentageStaked, setPercentageStaked] = useState<string>("");
  const [absoluteStaked, setAbsoluteStaked] = useState<number>();
  const [moonrunnerOffset, setMoonrunnerOffset] = useState<string>("");

  const [totalTokens, setTotalTokens] = useState<number>();

  const { mrContract, mrAbi, trophiesContract } =
    useContext<SetupContextProps>(SetupContext);
  const { provider, signer } = useProvider();

  async function getStakedValues() {
    if (signer) {
      const contractInstance = showDragons
        ? dragonsContractConnected
        : mrContractConnected;
      const totalSupply = await contractInstance.totalSupply();
      const totalSupplyString = totalSupply.toString();
      const totalSupplyNumber = Number(totalSupplyString);

      setTotalTokens(totalSupplyNumber);

      const balanceOfTokensStaked = await contractInstance.balanceOf(
        trophiesContract
      );

      const numberOfStakedTokens = balanceOfTokensStaked.toNumber();

      setAbsoluteStaked(numberOfStakedTokens);

      const percentageStaked = (numberOfStakedTokens / totalSupplyNumber) * 100;

      const percentageStakedString = percentageStaked + "%";

      const moonrunnerPercentageOffset = percentageStaked - 10 + "%";

      setMoonrunnerOffset(moonrunnerPercentageOffset);

      setPercentageStaked(percentageStakedString);
    }
  }

  useEffect(() => {
    getStakedValues();
  }, [dragonsContractConnected, mrContractConnected, showDragons]);

  return (
    <>
      <div className="column">
        <div className="loading-bar">
          <div
            className="loading-bar-progress"
            style={{
              position: "absolute",
              width: percentageStaked,
              height: "100%",
            }}
          ></div>
          <div
            className="loading-bar-moonrunner"
            style={{
              marginLeft: moonrunnerOffset,
              position: "relative",
              width: "20%",
              height: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="cursor"
              src={showDragons ? dragonHead : bouncingWolf}
              className="loading-bar-img"
            />
          </div>
        </div>
      </div>
      <div></div>
      <div className="columns is-centered is-vcentered">
        <div className="number-staked">
          {absoluteStaked} / {totalTokens} staked
        </div>
      </div>
    </>
  );
};

export default LoadingBar;
