import { useContext, useEffect, useState } from "react";
import styles from "./WeaponToBloodActionButtons.module.scss";
import { SetupContext } from "../../index";
import { useProvider } from "../../hooks/useProvider";
import { ethers, utils } from "ethers";
import { usePrimordia } from "../../hooks/usePrimordia";
import RandomizeButton from "../../assets/randomize.png";
import RandomizeButtonHovered from "../../assets/randomize-hover.png";
import BurnWeaponButton from "../../assets/burn-weapon.png";
import BurnWeaponButtonHovered from "../../assets/burn-weapon-hover.png";
import LoadingAnimation from "../../assets/rolling-loading-animation.gif";

const WeaponToBloodActionButtons = (props) => {
  const { address, weaponToBloodContract, weaponToBloodAbi } =
    useContext(SetupContext);
  const { primordiaBlood } = usePrimordia();
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bloodIds, setBloodIds] = useState([]);
  const [randomizeButtonHovered, setRandomizeButtonHovered] = useState(false);
  const [burnWeaponButtonHovered, setBurnWeaponButtonHovered] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const showModal = bloodIds.length > 0;
  const { provider, signer } = useProvider();

  const weaponToBloodContractConnected = !!signer
    ? new ethers.Contract(
        weaponToBloodContract,
        weaponToBloodAbi,
        provider
      ).connect(signer)
    : undefined;

  useEffect(() => {
    const filter = {
      address: weaponToBloodContract,
      topics: [utils.id("RandomBloodMinted(address,uint256[])")],
    };
    if (address) {
      provider.on(filter, (log) => {
        const [wallet, bigInts] = utils.defaultAbiCoder.decode(
          ["address", "uint256[]"],
          log.data
        );
        if (wallet.toLowerCase() === address.toLowerCase()) {
          setLoading(false);
          const bloodIds = bigInts.map((bigInt) => Number(bigInt.toString()));
          setBloodIds(bloodIds);
        }
      });
    }
  }, [address]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.weapons.length < 4) {
      setLimitExceeded(false);
    }
  }, [props.weapons]);

  return (
    <>
      <br />
      <div>
        Using the randomization feature uses chainlink, and will increase the
        gas in the transaction. Burning 3 weapons at a time will split this
        chainlink cost across the 3 weapons and is advised.
      </div>
      <br />
      {limitExceeded && (
        <div className={styles.warningMessage}>
          Only up to 3 weapons are allowed to be randomly trained at once
        </div>
      )}
      <br />
      <div className={styles.buttons}>
        <img
          onClick={async () => {
            if (props.weapons.length > 0) {
              const pendingTx =
                await weaponToBloodContractConnected.burnWeaponsForBlood(
                  props.weapons
                );
              props.clearSelectedWeapons();
              setLoading(true);
              const tx = await pendingTx.wait();
              setLoading(false);
              const mintedTopic = utils.id(
                "TransferBatch(address,address,address,uint256[],uint256[])"
              );
              const mintedEvent = tx.events.find(
                (event) => event.topics[0] === mintedTopic
              );
              console.log(mintedEvent);
              // eslint-disable-next-line no-unused-vars
              const [ids, values] = utils.defaultAbiCoder.decode(
                ["uint256[]", "uint256[]"],
                mintedEvent.data
              );
              console.log({ ids, values });
              const bloodIdBigInts = ids.reduce(
                (accumulator, bloodId, index) => {
                  const bloodIdOccurrences = [];
                  for (let i = 0; i < values[index]; i++) {
                    bloodIdOccurrences.push(bloodId);
                  }
                  return accumulator.concat(bloodIdOccurrences);
                },
                []
              );
              setBloodIds(
                bloodIdBigInts.map((bigInt) => Number(bigInt.toString()))
              );
            }
          }}
          src={
            burnWeaponButtonHovered ? BurnWeaponButtonHovered : BurnWeaponButton
          }
          alt="Randomize"
          onMouseEnter={() => setBurnWeaponButtonHovered(true)}
          onMouseLeave={() => setBurnWeaponButtonHovered(false)}
        />
        &nbsp;
        <img
          onClick={async () => {
            if (props.weapons.length > 3) {
              setLimitExceeded(true);
            } else if (props.weapons.length > 0) {
              await weaponToBloodContractConnected.burnWeaponsForRandomBlood(
                props.weapons,
                {
                  value: ethers.utils.parseEther("0.004"),
                }
              );
              props.clearSelectedWeapons();
              setLoading(true);
            }
          }}
          src={
            randomizeButtonHovered ? RandomizeButtonHovered : RandomizeButton
          }
          alt="Randomize"
          onMouseEnter={() => setRandomizeButtonHovered(true)}
          onMouseLeave={() => setRandomizeButtonHovered(false)}
        />
        <div onClick={() => setShowInfo(true)} className={styles.weaponInfo}>
          INFO
        </div>
      </div>
      <div
        className={`modal ${showModal ? "is-active" : ""}`}
        onBlur={() => setBloodIds([])}
      >
        <div className="modal-background"></div>
        <div className={`modal-card ${styles.bigModal}`}>
          <header className="modal-card-head">
            <p className="modal-card-title">
              You Trained well young Moonrunner
            </p>
            <button
              className="delete is-large"
              aria-label="close"
              onClick={() => setBloodIds([])}
            />
          </header>

          <section className="modal-card-body">
            <p>You have successfully trained {bloodIds.length} times.</p>
            <br />
            <p>These are your results:</p>
            <div className={styles.bloodImages}>
              {primordiaBlood.length > 0 &&
                bloodIds.map((bloodId) => (
                  <img
                    src={
                      primordiaBlood.find((blood) => blood.id === bloodId).image
                    }
                    alt={
                      primordiaBlood.find((blood) => blood.id === bloodId).name
                    }
                    className={styles.bloodImage}
                    key={Math.random()}
                  />
                ))}
            </div>
          </section>
        </div>
      </div>
      <div
        className={`modal ${loading ? "is-active" : ""}`}
        onBlur={() => setLoading(false)}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              Waiting for the Training to Complete
            </p>
            <button
              className="delete is-large"
              aria-label="close"
              onClick={() => setLoading(false)}
            />
          </header>

          <section className={`modal-card-body ${styles.center}`}>
            <img src={LoadingAnimation} alt="loading" />
          </section>
        </div>
      </div>
      <div
        className={`modal ${showInfo ? "is-active" : ""}`}
        onBlur={() => setShowInfo(false)}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Training explained</p>
            <button
              className="delete is-large"
              aria-label="close"
              onClick={() => setShowInfo(false)}
            />
          </header>

          <section className="modal-card-body">
            <div>
              Regular (Burn Weapon): This will allow you to burn your weapon and
              receive a blood, the rarities are dialled down on this function.
              This will not give you a chance at upgrading your blood.
            </div>
            <br />
            <div>
              Randomize: This feature uses a chance mechanic to train your
              weapon giving you a shot at upgrading your blood tier OR
              downgrading it.
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default WeaponToBloodActionButtons;
