import { useContext, useEffect } from "react";
import { SetupContext } from "../index.js";
import { useProvider } from "./useProvider.js";
import { ethers } from "ethers";

export const useWeaponBurn = () => {
  const { address, weaponBurnContract, weaponBurnAbi } =
    useContext(SetupContext);
  const { provider, signer } = useProvider();

  const weaponBurnContractConnected = !!signer
    ? new ethers.Contract(weaponBurnContract, weaponBurnAbi, provider).connect(
        signer
      )
    : undefined;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return {
    weaponBurnContractConnected,
  };
};
