import { useState } from "react";
import Gutter from "./Gutter";
import "./Primordia.scss";

import { usePrimordia } from "../hooks/usePrimordia";
import { useTombstones } from "../hooks/useTombstones";
import WeaponToBloodActionButtons from "./WeaponToBloodActionButtons/WeaponToBloodActionButtons";

const Primordia = () => {
  const { primordiaWeapons, primordiaBlood, primordiaTickets } = usePrimordia();
  const [weaponCounts, setWeaponCounts] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [selectedWeapons, setSelectedWeapons] = useState([]);
  const { tombstoneObjects } = useTombstones();

  const clearSelectedWeapons = () => {
    setSelectedWeapons([]);
    setWeaponCounts([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  };

  function incrementCount(weaponId) {
    const userBalance = Number(
      primordiaWeapons.find((weapon) => weapon.id === weaponId).userBalance
    );
    const newCounts = [...weaponCounts];
    if (userBalance > newCounts[weaponId]) {
      newCounts[weaponId] += 1;
      setWeaponCounts(newCounts);
      const incrementedWeapon = primordiaWeapons.find(
        ({ id }) => id === weaponId
      );
      setSelectedWeapons((prevSelectedWeapons) => [
        ...prevSelectedWeapons,
        incrementedWeapon,
      ]);
    }
  }

  function decrementCount(weaponId) {
    const newCounts = [...weaponCounts];
    if (newCounts[weaponId] > 0) {
      newCounts[weaponId] -= 1;
      setWeaponCounts(newCounts);
      const weaponIndex = selectedWeapons.findIndex(
        (weapon) => weapon.id === weaponId
      );
      const newWeapons = selectedWeapons
        .slice(0, weaponIndex)
        .concat(selectedWeapons.slice(weaponIndex + 1, selectedWeapons.length));
      setSelectedWeapons(newWeapons);
    }
  }

  return (
    <div className="container">
      <section className="section">
        <h1 className="primordia-title">Weapons</h1>
        <div className="columns den-info is-vcentered">
          <div className="column is-11">
            <div className="columns weapon-image-container">
              {primordiaWeapons.map((weapon) => (
                <div
                  className="column training-weapon is-centered"
                  key={weapon.id}
                >
                  <img
                    className="weapon-image"
                    src={weapon.image}
                    alt="weapon"
                  />
                  <div className="weapon-counter">
                    <div className="item-count"> {weapon.userBalance} </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section weapons-training">
        <div className="full-flex">
          <h1 className="faq-title heading">Weapon training</h1>
          <div className="den-info">
            <div>
              <div>
                Perform weapon training with your weapons.
                <br />
                One weapon = one blood&nbsp;&nbsp;
                <br />
                Or re-roll your dragons.
                <br />
              </div>
              <div className="columns weapon-image-container">
                {primordiaWeapons
                  .slice(0, primordiaWeapons.length - 1)
                  .map((weapon) => (
                    <div
                      className="column training-weapon is-centered"
                      key={weapon.id}
                    >
                      <img
                        className="weapon-image"
                        src={weapon.image}
                        alt="weapon"
                      />
                      <div className="weapon-counter">
                        {weaponCounts[weapon.id]}
                      </div>
                      <figcaption className="add-remove-button">
                        <span
                          className="add-button"
                          onClick={() => incrementCount(weapon.id)}
                        >
                          +
                        </span>
                        <span
                          className="remove-button"
                          onClick={() => decrementCount(weapon.id)}
                        >
                          &nbsp;-
                        </span>
                      </figcaption>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <WeaponToBloodActionButtons
            weapons={selectedWeapons.map((weapon) => weapon.id)}
            clearSelectedWeapons={clearSelectedWeapons}
          />
        </div>
        <div className="full-flex" style={{ alignSelf: "baseline" }}>
          <div className="selected-weapons-container">
            {selectedWeapons.length > 0 && (
              <div className="burn-counter">{selectedWeapons.length}</div>
            )}
            {selectedWeapons.slice(-5).map((weapon, index) => (
              <div
                key={Math.random()}
                style={{
                  position: "absolute",
                  right: 20 + 20 * index,
                  top: 100 - 20 * index,
                }}
              >
                <img
                  className={`selected-weapon-image`}
                  src={weapon.image}
                  alt="weapon"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section">
        <h1 className="primordia-title">Blood</h1>
        <div>
          <div>
            <div className="columns weapon-image-container">
              {primordiaBlood.map((blood) => (
                <div
                  className="column training-weapon is-centered"
                  key={blood.id}
                >
                  <img className="weapon-image" src={blood.image} alt="blood" />
                  <div className="weapon-counter">
                    <div className="item-count"> {blood.userBalance} </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <h1 className="primordia-title">Tickets</h1>
        <div className="columns den-info is-vcentered">
          <div className="column is-4">
            <div className="columns weapon-image-container">
              {primordiaTickets.map((ticket) => (
                <div
                  className="column training-weapon is-centered"
                  key={ticket.id}
                >
                  <img
                    className="weapon-image"
                    src={ticket.image}
                    alt="weapon"
                  />
                  <div className="weapon-counter">
                    <div className="item-count"> {ticket.userBalance} </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <h1 className="primordia-title">Tombstones</h1>
        <div className="columns den-info is-vcentered">
          <div className="column is-8">
            <div className="columns weapon-image-container">
              {tombstoneObjects.map((tombstone) => (
                <div className="column training-weapon is-centered">
                  <img
                    className="weapon-image"
                    src={tombstone.image}
                    alt="weapon"
                  />
                  <div className="weapon-counter">
                    <div className="item-count"> {tombstone.userBalance} </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Gutter />
    </div>
  );
};

export default Primordia;
